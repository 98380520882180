import {environment} from "../../environments/environment";
import {Injectable, OnChanges, OnInit} from "@angular/core";
import {Contact, Cart, CartSlot, Product, Offer, OfferType, cartToOrder} from "gul-si-commons";
import {NotificationService} from "../core/services/notification.service";
import {SimpleRestService} from "../core/services/simple-rest.service";
import {HttpClient} from '@angular/common/http';
import { OrderService } from "./rest/order.service";
import { Router } from "@angular/router";
const shipping = {
  "firstName" : "Katharsis-event",
  "lastName" : "SAS",
  "cellularNumber": "0781553833",
  "email": "contact@katharsis-event.fr",
  "address": {
    "zipcode": "94110",
    "number": "",
    "street": "  ",
    "city": "Arceuil",
    "country": "France"
  }
}
@Injectable({
  providedIn: 'root'
})
export class CartService extends SimpleRestService {
  public cart: Cart


  constructor(private notification: NotificationService, public http: HttpClient, public orderService: OrderService, public route: Router) {
    super(http, 'cart', ['oms'])
    this.init()
  }


  productQuantity(product: Product, offer: Offer): number {
    const line =  this.cart?.articles?.find((a) => a?.product?._id ===  product?._id && a?.offer?._id ===  offer?._id )
    return line ? line.qte : 0
  }

  totalCount(): number {
    return this.cart?.articles?.reduce((totalQty, orderLine) => totalQty + orderLine.qte,
    0)
  }

  init(): void {
    try {
 
    this.get(0, 1).subscribe(cart => {
        if (cart.data.length === 0) {
          this.create().subscribe(cart => {
            this.cart = cart.data.pop()
          }, error => {
            this.notification.error('KO', error)
          })
        } else {
          this.cart = cart.data.pop()
       
        }
        this.cart.shipping = new Contact().fromObject(shipping)
        this.cart = new Cart().fromObject(this.cart)
      },
      error => {
        this.notification.succes('INFO', 'vous devez etre authentifier pour avoir un panier')
      }
    )
    }
    catch (e) {
    }
  }

  add(cartSlot: CartSlot): void {
    if(!this.cart) {
      this.notification.succes('INFO', 'vous devez etre authentifier pour avoir un panier')
      return
    }
    this.cart.add(cartSlot)
  
    this.update(this.cart._id, this.cart).subscribe(order => {
      this.cart = new Cart().fromObject(order.data.pop())
      this.notification.succes('OK', 'produit ajouté au panier')
    },
    error => {
      this.notification.error('ERROR', error)
    })
  }

  remove(cartSlot: CartSlot): void {
    if(!this.cart) {
      this.notification.succes('INFO', 'vous devez etre authentifier pour avoir un panier')
      return
    }
    this.cart.remove(cartSlot)
    this.update(this.cart._id, this.cart).subscribe(order => {
      this.cart = new Cart().fromObject(order.data.pop())
      this.notification.succes('OK', 'produit supprimé du panier')
    },
    error => {
      this.notification.error('ERROR', error)
    })
  }

  setCustomer(customer: Contact): void {
    this.cart.customer = customer;
    this.update(this.cart._id, this.cart).subscribe(event => {
      this.notification.succes('OK', 'Adresse de facturation sauvegardée')
    },
    error => {
      this.notification.error('ERROR', error)
    })
  }


  setStartDate(event: any) {
    let startDate = {
      date :  new Date(event.date),
      slot :  event.slot,
    }
    this.cart.articles.map(e =>  {e.startDate = startDate})
    this.cart.startDate = startDate
    this.update(this.cart._id, this.cart).subscribe(event => {
      return
    })
  }

  
  setEndDate(event: any) {
    let endDate = {
      date :  new Date(event.date),
      slot :  event.slot,
    }
    this.cart.articles.map(e =>  {
      if (e.offer.type === OfferType.rent) {
        e.endDate = endDate
      }
    })
    this.cart.endDate = endDate
    this.update(this.cart._id, this.cart).subscribe(event => {
      return
    })
  }


  createOrder() {
    this.orderService.post([cartToOrder(this.cart)]).subscribe((order) => {
      this.delete(this.cart._id).subscribe((cart) => {
        this.route.navigate(['/commandes'])
      })
      this.notification.succes('COMMANDE', 'crée avec succès, un mail va vous parvenir prochainement')
    },
    error => {
      this.notification.error('ERROR', error)
    })
  }
}
