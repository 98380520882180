import { Component, Input, OnInit } from '@angular/core';
import { Order, Price } from 'gul-si-commons';
import * as moment from 'moment';
import { OrderService } from 'src/app/service/rest/order.service';
import { Scrolable } from '../../interface/scrolable.interface';
import { NotificationService } from '../../services/notification.service';
import { concat } from 'lodash';

@Component({
  selector: 'app-order-list',
  templateUrl: './order-list.component.html',
  styleUrls: ['./order-list.component.scss']
})
export class OrderListComponent implements OnInit {
 @Input()  service: Scrolable
 orders: Order[] = [];
 total = -1;
 page = 0;
 size = 10;
 throttle = 10;
 scrollDistance = 1;
 scrollUpDistance = 2;

 constructor(protected notification: NotificationService) {}

 ngOnInit(): void {
   this.service.get( this.page, this.size).subscribe(next => {
    this.orders = next.data.map(o => { return new Order().fromObject(o)});
    this.total = next.length
    },
    error => {
      this.notification.error('ERROR filters', error)
    })}

  onScrollDown(ev?: any) {
    console.log('scroll', ev)
    if(this.total > 0 && this.page + 1  * this.size < this.total) {
      this.page += 1;
         this.service.get( this.page, this.size).subscribe(next => {
              this.orders = concat(this.orders, next.data.map(o => { return new Order().fromObject(o)}))
            },
            error => {
              this.notification.error('ERROR', error)
            }
          )
      }
  }
}
