<div class="wrapper">
  <div id="catalogue" class="item" [routerLink]="['/catalogue/arborescence']">
    <span class="title" id="tree-redirect"><i class="fa fa-sitemap"></i></span>
    <h5>Catalogue</h5>
</div>
  <div id="order" class="item" [routerLink]="['/gestion-commandes']">
    <span class="title" id="family-redirect"><i class="fa fa-shopping-cart"></i></span>
    <h5>Commandes</h5>
  </div>
  <!-- <div class="item" [routerLink]="['/catalogue/promotions']">
    <span class="title" id="family-redirect"><i class="fa fa-percent"></i></span>
    <h5>Clients et Promotions</h5>
  </div> -->
</div>