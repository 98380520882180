import { Component } from '@angular/core';
import { userInfo } from 'os';
import { AdminUserService } from 'src/app/service/rest/admin-user.service copy';

@Component({
  selector: 'app-promotion',
  templateUrl: './promotion.component.html',
  styleUrls: ['./promotion.component.scss']
})
export class PromotionComponent {

  constructor(public userService: AdminUserService ) {

  }

}
