import { Component } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ResultListComponent } from 'src/app/core/component/result-list/result-list.component';

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss']
})
export class UserListComponent extends ResultListComponent {

  timeout

  refresh(): void {
  }

  override ngOnInit(): void {
      this.array = []
  }


  override ngOnChanges(): void {
    this.client.get().subscribe(e => { 
      e.data.forEach(user => {
        this.client.getById(user.id).subscribe(userInfo => {
          user = {keycloakInfo : user, userInfo: userInfo.data[0], validator: null}
          let promotionFormControl = new FormControl(user.userInfo.promotion)
          promotionFormControl.valueChanges.subscribe((e) => {
            clearTimeout(this.timeout);

            // Make a new timeout set to go off in 1000ms (1 second)
            this.timeout = setTimeout(function () {}, 1000);
            this.client.updateField(user.userInfo._id, ['promotion'], e).subscribe(r => {
            })
          
          })
          user.validator = promotionFormControl
          this.array.push(user)})
      });})
  }

}
