import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BasicFormComponent } from './basic-form/basic-form.component';
import { UserAddressFormComponent } from './user-address-form/user-address-form.component';
import { MAT_DIALOG_DEFAULT_OPTIONS, MatDialogModule } from '@angular/material/dialog';
import { ReactiveFormsModule } from '@angular/forms';
import { ButtonsModule } from '../buttons/buttons.module';
import { TreeNodeFormComponent } from './tree-node-form/tree-node-form.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { InputModule } from '../input/input.module';



@NgModule({
  declarations: [
    BasicFormComponent,
    UserAddressFormComponent,
    TreeNodeFormComponent
  ],
  exports: [
    BasicFormComponent,
    UserAddressFormComponent
  ],
  imports: [
    CommonModule,
    InputModule,
    MatDialogModule,
    ReactiveFormsModule,
    ButtonsModule,
    FontAwesomeModule
  ],
  providers: [
    {provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: {hasBackdrop: false}}
  ]
})
export class FormModule { }
