<ng-container *ngFor="let i of array">
    <div class="box">
        <div class="columns">
            <div class="column">
                <p class="subtitle">Informations</p>
                <p> {{i.keycloakInfo.username}}</p>
                <p> {{i.keycloakInfo.firstName}} {{i.keycloakInfo.lastName}}</p>
                <p> {{i.keycloakInfo.email}}</p>    
            </div>
            <div class="column">
                <p class="subtitle">Tarifs</p>
                <input class="input" id="{{i.userInfo.promotion}}" [formControl]="i?.validator">
            </div>
        </div>
        
    </div> 
</ng-container>
