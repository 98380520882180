import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { faChevronLeft, faChevronRight, faLeaf } from '@fortawesome/free-solid-svg-icons';
import { Filter, FilterBuilder, TreeNode } from 'gul-si-commons';
import { Tree } from 'gul-si-commons';
import { TreeNodeFormComponent } from 'src/app/core/component/form/tree-node-form/tree-node-form.component';
import { NotificationService } from 'src/app/core/services/notification.service';
import { CategoryService } from 'src/app/service/rest/category.service';
import { ProductService } from 'src/app/service/rest/product.service';
import { NodeService, TreeService } from 'src/app/service/rest/tree.service';

@Component({
  selector: 'app-product-inventory',
  templateUrl: './product-inventory.component.html',
  styleUrls: ['./product-inventory.component.scss']
})
export class ProductInventoryComponent implements OnInit {
  faChevronRight = faChevronRight
  faChevronLeft = faChevronLeft


  constructor(public route: Router, public dialog: MatDialog, public treeService: TreeService, public categoryService: CategoryService, public nodeService: NodeService, public notification: NotificationService, public catalogue: ProductService) { }

  tree: Tree
  isTreeOpen = false
  selected: TreeNode
  filter: FilterBuilder = new FilterBuilder()
  selectedTab: string
  tabs = ["Produits", "Bundle"]



  ngOnInit(): void {
    this.treeService.get(0, 10).subscribe(res => {
      //TODO clean this shit
      this.tree = new Tree().fromObject(res.data[0]);
    },
      error => {this.notification.error('ERROR', error)}
    )
  }

  toggleTree() {
    this.isTreeOpen =  !this.isTreeOpen
  }
  saveNode(node: TreeNode) {
      this.nodeService.update(node._id, node).subscribe(res => {
        //TODO clean this shit
        this.tree = new Tree().fromObject(res.data[0]);
        this.selected = node
        this.notification.succes('OK', 'Nouvelle catégorie sauvegardée')

      },
        error => {this.notification.error('ERROR', error)}
      )
  }

  editNode() {
    const dialogRef = this.dialog.open(TreeNodeFormComponent,{
      data: {
        object: this.selected,
      },
      panelClass: 'custom-dialog',
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result)
        this.saveNode(result)
    });
  }

  selectNode(node: TreeNode) {
    this.selected = node
    this.filter =  new FilterBuilder().fieldEquals('associatedNode', node._id) 
  }

  selectAllProduct() {
    this.selected = null
    this.filter =  new FilterBuilder() 
  }

  addSubNode(node: TreeNode) {
    const id = node._id
    if(node.childs.length === 0) {
      this.catalogue.updateAssociatedNode(id, this.tree.root._id).subscribe(res => {
    
  
        this.notification.succes('OK', 'Produits déplacés dans /')
  
        },
        error => {this.notification.error('ERROR', error)})
    }
    
    this.nodeService.add(id).subscribe((res) => {
      this.tree = new Tree().fromObject(res.data[0]);
      this.notification.succes('OK', 'Noeud ajouté')

      },
      error => {this.notification.error('ERROR', error)})
  }

  deleteNode(node: TreeNode) {
    const id = node._id
    this.catalogue.updateAssociatedNode(id, this.tree.root._id).subscribe(res => {
      this.nodeService.delete(id).subscribe(res => {
        this.tree = new Tree().fromObject(res.data[0]);
        this.notification.succes('OK', 'Noeud supprimé')
        },
        error => {this.notification.error('ERROR', error)})

      this.notification.succes('OK', 'Produits déplacés dans /')

      },
      error => {this.notification.error('ERROR', error)})
  }


  associateNode(ids: string[]) {
    // to Implement
    this.catalogue.setAssociatedNode(ids, this.selected._id).subscribe( e => {})
  }

  leaves() {
    let leaves = [];
    if (this.tree !== undefined) {
    this.tree.visit((node) => {
      if (node.childs.length === 0) leaves.push(node);
    });
    }
    leaves =  leaves.map((leaf) => {return {id: leaf._id, name: leaf.label}})
    return leaves.sort((a, b) => a.name.localeCompare(b.name));
  }

  create() {
    let nodeId = this.tree.root._id
    if ( this.selected !== null )
      nodeId = this.selected._id
    this.catalogue.createAndAssociate(nodeId).subscribe( res => 
      this.route.navigate(['/produit', res.data[0]._id, 'edit'])
    )    
  }


}
