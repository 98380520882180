import { Component, Input, Output, EventEmitter } from '@angular/core';
import { faTruckFast } from '@fortawesome/free-solid-svg-icons';
import { Order, OrderStepName } from 'gul-si-commons';
import { AuthGuardService } from 'src/app/core/services/auth/auth-guard.service';
import { AdminOrderService } from 'src/app/service/rest/admin-order.service';

@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss']
})
export class EditComponent {
  faTruckFast = faTruckFast
  OrderStepName = OrderStepName

  @Input() order: Order
  @Input() disableAction = true;

  @Output() nextOrderStep = new EventEmitter()

  constructor(protected authService: AuthGuardService, protected adminOrderService: AdminOrderService) {

  }


}
