  <div class="screen-margin" *ngIf="objectAsForm" [formGroup]="objectAsForm"> 
    <div class="main-info">
      <div class="pictures">
        <div class="wrapper">
          <img class="picture" id="image0" src="{{objectAsForm?.controls?.images?.controls[0]?.controls?.url?.value ? objectAsForm.controls.images.controls[0].controls.url.value : '/assets/no-image.png'}} " alt="Placeholder image">
          <div class="file-input">
            <input type="file" id="file" class="file"  (change)="onFileSelected($event, 0)">
            <label for="file"><fa-icon [icon]="faUpload"></fa-icon></label>
          </div>
        </div>
        <div class="wrapper">
          <img class="picture" id="image1" src="{{objectAsForm?.controls?.images?.controls[1]?.controls?.url?.value ? objectAsForm.controls.images.controls[1].controls.url.value : '/assets/no-image.png'}} " alt="Placeholder image">
          <div class="file-input">
            <input type="file" id="file" class="file"  (change)="onFileSelected($event, 1)">
            <label for="file"><fa-icon [icon]="faUpload"></fa-icon></label>
          </div>
        </div>
        <div class="wrapper">
          <img class="picture" id="image2" src="{{objectAsForm?.controls?.images?.controls[2]?.controls?.url?.value ? objectAsForm.controls.images.controls[2].controls.url.value : '/assets/no-image.png'}} " alt="Placeholder image">
          <div class="file-input">
            <input type="file" id="file" class="file"  (change)="onFileSelected($event, 2)">
            <label for="file"><fa-icon [icon]="faUpload"></fa-icon></label>
          </div>
        </div>
        <div class="wrapper">
          <img class="picture" id="image3" src="{{objectAsForm?.controls?.images?.controls[3]?.controls?.url?.value ? objectAsForm.controls.images.controls[3].controls.url.value : '/assets/no-image.png'}} " alt="Placeholder image">
          <div class="file-input">
            <input type="file" id="file" class="file"  (change)="onFileSelected($event, 3)">
            <label for="file"><fa-icon [icon]="faUpload"></fa-icon></label>
          </div>
        </div>
      </div>
      <div class="info">
        <h3 (click)="getArticles()">Caractéristiques principales</h3>
        <div class="input-wrapper">
          <input class="effect" type="text" autocomplete="country-name" formControlName="name" placeholder="Nom">
          <span class="focus-border"></span>
        </div>
        <div formArrayName="offers" class="offer-tabs">
          <ng-container *ngFor="let group of objectAsForm.controls.offers.controls; let i = index">
            <div [formGroup]="group" class="tab">
              <div class="title">
                <app-visible-button [isVisible]="objectAsForm.value.offers[i].isVisible" [_id]="objectAsForm.value.offers[i]._id" (toggle)="changeOfferVisibility($event._id, $event.isVisible)"></app-visible-button>
                <p>{{objectAsForm.controls.offers.controls[i].value.type}}</p>
              </div>  

              <div *ngIf="objectAsForm.value.offers[i].isVisible" formGroupName="price">
                <div class="input-wrapper">
                  <input class="effect" type="text" autocomplete="country-name" formControlName="ht" placeholder="Prix">
                  <span class="focus-border"></span>
                </div>
              </div>
              <div *ngIf="!objectAsForm.value.offers[i].isVisible">
                <p>cette offre est désactivée</p>
              </div>
            </div>
          </ng-container>
        </div>
        <app-cta-button [text]="'Sauvegarder'" (click)="submit()"></app-cta-button>
      </div>
    </div>
    <div class="sku"></div>
    <div class="description">
      <h3> Description </h3>
      <div class="input-wrapper">
        <input class="effect" type="text" autocomplete="country-name" formControlName="description" placeholder="Description">
        <span class="focus-border"></span>
      </div>
    </div>
    <div class="attributes">
      <h3> Attributs </h3>
      <ng-container formArrayName="attributes">
        <ng-container class="attribute" *ngFor="let group of objectAsForm.controls.attributes.controls; let i = index">
            <div class="attribute" [formGroup]="group">
              <h5>{{group.controls.label.value}}</h5>
              <div class="input-wrapper">
                <input class="effect" type="text" autocomplete="country-name" formControlName="value" placeholder="Non renseigné">
                <span class="focus-border"></span>
              </div>
            </div>
        </ng-container>
      </ng-container>
    </div>
    <div class="articles">
      <h3> Produits inclus dans l'offre </h3>
      <p>Ajouter des produits et leur quantité pour faire de cette offre un lot</p>
      <app-input [control]="productSearch" [placeholder]="'nom du produit'" [results]="searchResult" (selectResultEvent)="addToBundle({product: $event, qte: 1})"></app-input>
      <app-article-list [articles]="objectAsForm?.controls?.articles?.value" [percentPromotion]="0" (plusTriggerEvent)="addToBundle($event)" (minusTriggerEvent)="removeFromBundle($event)"></app-article-list>
    </div>
    <div>
      <h3> Codes associés au produit </h3>
      <p>Ajouter des nouveaux codes bar ou qrCode en les scanants</p>
      <app-scanner [productId]="productId"></app-scanner>
    </div>
</div>
